import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const app = initializeApp({
    apiKey: "AIzaSyAheD_bKxAcxTnerzUBM0ARFXfi_d-24_w",
    authDomain: "fir-zava.firebaseapp.com",
    projectId: "fir-zava",
    storageBucket: "fir-zava.appspot.com",
    messagingSenderId: "671021401375",
    appId: "1:671021401375:web:5b40394f53b63efb691c2a",
    measurementId: "G-SSJDXWY7MF"
});
getAnalytics(app);
export const auth = getAuth(app);
export default app;